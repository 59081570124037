import React, { Component } from 'react'
import {Link} from "react-router-dom";
import ls from "local-storage";
let isAdmin= ls.get('xxxx');

export default class Menu extends Component {
  render() {
    

        
    }
}
