import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import 'react-data-table-component-extensions/dist/index.css';
import { useLocation, useNavigate } from "react-router-dom"
import MenuItem from "@mui/material/MenuItem";
import ls from "local-storage";
let isAdmin = ls.get('xxxx');


const prestatairesTables = [

    {
        name: 'Nom & Prenoms',
        selector: row => row.nom + ' ' +row.prenom,
        cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:16}
    },
    {
        name: 'Ville',
        selector: row => row.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone,
        wrap:true,
    }
];


export const handleCheckItem = (item) => {
console.log('iiiiteeemm',item)
console.log('iiiiteeemm',item.target.value)
};

export const Centre = () => {
    const navigate = useNavigate();

    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [isLoading, setLoading] = React.useState(false);
    const [searchFieldprestataire, setSearchFieldprestataire] = useState("");
    const [immatriculation, setImmatriculation] = React.useState("");
    const [commentVehicle, setCommentVehicle] = React.useState("");
    const [isShowPrestataire, setShowPrestataire] = React.useState(false);
    const [Prestataire, setPrestataire] = React.useState(null);
    const [prestataires, setPrestataires] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState('');


    const getPrestataires = async () => {
        helpers
            .getAllPrestataires()
            .then(async r => {
                console.log('resp', r);
                setLoading(false);
                if (r.status === 200) {
                    setPrestataires( r.data);
                }
            })
    };

    const handleShowPrestataire = (value) => {
        setShowPrestataire(value.target.checked)
    };

    const [selectedRowss, setSelectedRows] = React.useState(false);


    const toggledClearSelectedRows = (val) => {
        //setClearSelectedRows(val);
    };




    function newVehicle(e) {
        e.preventDefault();

        if(immatriculation.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez ajouter l\'immatriculation.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(site.toString().trim()  === ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez choisir le site.' });
            setTimeout(()=>setOpenToast({ isOpenToast:false, txt:'' }),6000);
            return;
        }

        // if(!Prestataire?.email && isShowPrestataire){
        //     setOpenToast({
        //         isOpenToast: true,
        //         txt: 'Veuillez choisir le prestataire.'
        //     });
        //     return;
        // }
        // if((Prestataire?.email && isShowPrestataire) && Prestataire?.email.trim() === "" || Prestataire?.email === '----' ){
        //     setOpenToast({
        //         isOpenToast: true,
        //         txt: 'Veuillez choisir le prestataire.'
        //     });
        //     return;
        // }
        // setOpenToast({...openSnack, setOpenToast:false});

        let body = {
            immatriculation:  immatriculation.trim(),
            commentaire:  commentVehicle.trim(),
        };
        if(site)
            body.siteId = site;

        // if(Prestataire?.email && isShowPrestataire && Prestataire?.email.trim() !== "" || Prestataire?.email !== '----')
        //     body.prestataireId = Prestataire.id;
        console.log('le body', body);
        helpers
            .saveVehicle(body)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: 'Enregistrement effectué avec succès.'
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setImmatriculation("");
                        setCommentVehicle("");
                        navigate("/dashboard/vehiculeprestataire", { replace: true });
                        window.location.reload()

                }
            })
    }


    useEffect(()=>{
        setLoading(true);
        async function init(){
            getPrestataires().catch(console.error);

        helpers
            .getAllSites()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    if(isAdmin !== "AAAA" ){
                        const user_ = await JSON.parse(await ls.get('user'));
                        setSite( user_?.siteId);
                    }
                    const AllSites = [];
                    for(let i = 0; i<r.data.length; i++){
                        AllSites.push({value:r.data[i].id, label:r.data[i].nom})
                    }
                    await setSites(AllSites);
                }
            })
        }
        init();
    },[]);

    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        setPrestataire(selectedRows[0])
    };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Ajout Vehicule</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Véhicule
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                            City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">

                        <div style={{width:'50%'}} className="row">
                            <div className="col">
                                <Box component="form" onSubmit={newVehicle} sx={{ mt: 1 }}
                                     Validate
                                     autoComplete="on"   >
                                <div className="">
                                    <h4 className="m-0 text-secondary">Vehicule</h4>
                                </div>
                                <div className="row">
                                    { isAdmin === "AAAA" && <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="type"
                                        select
                                        label="Site"
                                        value={site}
                                        onChange={(event) => {
                                            setSite(event.target.value);
                                        }}
                                    >
                                        {sites.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>}

                                    <TextField
                                      //  size={'small'}
                                      //   inputProps={{
                                      //       style: {
                                      //           height: "600px",
                                      //       },
                                      //   }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="immatriculation"
                                        label="Immatriculation"
                                        name="immatriculation"
                                      //  autoComplete="nom"
                                        autoFocus
                                        value={immatriculation}
                                        onChange={(value)=>{setImmatriculation(value.target.value); }}
                                    />
                                </div>
                                <div className="row">
                                    <TextField
                                        margin="normal"
                                       // required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        //  autoComplete="nom"
                                        multiline={true}
                                        rows={3}
                                        autoFocus
                                        value={commentVehicle}
                                        onChange={(value)=>{setCommentVehicle(value.target.value); }}
                                    />
                                </div>
                                {/*<FormControlLabel*/}
                                {/*    control={<Checkbox onChange={handleShowPrestataire} value="remember" color="primary" />}*/}
                                {/*    label="Choisir le prestataire."*/}
                                {/*/>*/}
                                {/*    {isShowPrestataire && <div style={{  }} >*/}
                                {/*        <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">*/}
                                {/*            <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">*/}
                                {/*                Prestataire: {Prestataire != null ?( Prestataire?.nom != undefined ? Prestataire?.nom: '') + ' ' + Prestataire?.prenom != undefined ? Prestataire?.prenom : '' : '----'}*/}
                                {/*            </div>*/}
                                {/*            <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">*/}
                                {/*                email: {Prestataire != null ? Prestataire?.email  : '----'}*/}
                                {/*            </div>*/}
                                {/*            <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">*/}
                                {/*                telephone: {Prestataire != null ? Prestataire?.telephone  : '----'}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">*/}
                                {/*            <Paper*/}
                                {/*                component="form"*/}
                                {/*                sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}*/}
                                {/*            >*/}
                                {/*                <InputBase*/}
                                {/*                    sx={{ ml: 1, flex: 1 }}*/}
                                {/*                    placeholder="Rechercher"*/}
                                {/*                    inputProps={{ 'aria-label': 'Rechercher' }}*/}
                                {/*                    onChange={(e)=>{*/}
                                {/*                        e.preventDefault();*/}
                                {/*                        setSearchFieldprestataire(e.target.value);*/}
                                {/*                    }}*/}
                                {/*                    value={searchFieldprestataire}*/}
                                {/*                />*/}
                                {/*                <IconButton  onClick={() => {*/}
                                {/*                    rechercheprestataireindb()*/}
                                {/*                }} type="button" sx={{ p: '5px' }} aria-label="search">*/}
                                {/*                    <SearchIcon />*/}
                                {/*                </IconButton>*/}
                                {/*            </Paper>*/}
                                {/*            <DataTable*/}
                                {/*                pagination*/}
                                {/*                selectableRowsComponentProps={selectProps}*/}
                                {/*                dense*/}
                                {/*                columns={prestatairesTables}*/}
                                {/*                data={prestataires}*/}
                                {/*                selectableRows*/}
                                {/*                striped*/}
                                {/*                highlightOnHover*/}
                                {/*                persistTableHead*/}
                                {/*                fixedHeader={false}*/}
                                {/*                progressPending={false}*/}
                                {/*                noDataComponent={"Aucune données à afficher"}*/}
                                {/*                onRowClicked={(row, event) => {console.log('row clicked', row)}}*/}
                                {/*                onSelectedRowsChange={handleChange}*/}
                                {/*                paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </div>}*/}

                                <div  style={{marginTop:15}}  className="row">
                                <button
                                    onClick={(e)=>newVehicle(e)}
                                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer Vehicule</button>
                                </div>
                                </Box>
                            </div>
                        </div>

                    </div>
                    {/*fin body*/}
                </div>

                {/*ts miasa aloha hatreto*/}

                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
        </LoadingOverlay>
    )
};
