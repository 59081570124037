import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Papa from "papaparse";
import ls from "local-storage";
let isAdmin = ls.get('xxxx');

const allowedExtensions = ["csv"];
// const allowedExtensions = ["xlsx"];


let columns = [];
if(isAdmin === "AAAA" ) {
    columns = (( ShowConfirmDelete) => [
    // {
    //     name: 'Site',
    //     selector: row => row?.site?.nom,
    //     sortable:true,
    //     wrap:true,
    //     style:{fontSize:16}
    // },
    {
        name: 'Immatriculation',
        selector: row => row.immatriculation,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Prestataire',
        selector: row => ((row?.prestatairevrai?.nom  && row?.prestatairevrai?.nom ) + " " + (row?.prestatairevrai?.prenom  && row?.prestatairevrai?.prenom) ),
        wrap:true,
        sortable:true,
    },
    {
        name: 'Commentaire',
        selector: row => row?.commentaire ,
        wrap:true,
        sortable:true,
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

    ]);
}else {
    columns = (( ShowConfirmDelete) => [
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        // {
        //     name: 'Prestataire',
        //     selector: row => (row?.prestataire?.nom != undefined ? row?.prestataire?.nom : '') + " " + row?.prestataire?.prenom != undefined  ? row?.prestataire?.prenom : '' ,
        //     wrap:true,
        //     sortable:true,
        // },
        {
            name: 'Commentaire',
            selector: row => row?.commentaire ,
            wrap:true,
            sortable:true,
        },
        {
            name: '',
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

    ]);

}



const prestatairesTables = [
    // {
    //     name: 'Choisir',
    //     selector: row => row.selected,
    //     cell: d => <span><FormControlLabel
    //         control={<Checkbox onChange={handleCheckItem} value={d.id} color="primary" />}
    //         label=""
    //     /></span>,
    //     sortable:true,
    //     style:{fontSize:16}
    // },
    {
        name: 'Nom & Prenoms',
        selector: row => row.nom + ' ' +row.prenom,
        cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:16}
    },
    {
        name: 'Ville',
        selector: row => row.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone,
        wrap:true,
    }
];


export const Vehiculeprestataire = () => {
    const navigate = useNavigate();
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({ isOpenNomError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [prestataires, setPrestataires] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [searchFieldprestataire, setSearchFieldprestataire] = useState("");
    const [Immatriculation, setImmatriculation] = React.useState(false);
    const [idvehiculeprestataire, setIdvehiculeprestataire] = React.useState(null);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [showModalUpload, setShowModalUpload] = React.useState(false);
    const [files, setFiles] = useState(null);
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState('');
    const [Commentaire, setCommentaire] = React.useState('');

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const suppression = async (e)=> {
        helpers
            .supprVehiculeprestataire({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    getListeVehiculeprestataire().catch(console.error);
                    setOpen(false);
                }
            })
    };

    const [Prestataire, setPrestataire] = React.useState(null);
    const handleChange = ({ selectedRows }) => {
      //  setSelectedRows(selectedRows);
        setPrestataire(selectedRows[0])
    };

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item
                        .immatriculation
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );

        if(searchField == '')
            getListeVehiculeprestataire().catch(console.error);
        if(filteredTables != undefined)
            setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    useEffect(()=>{
        const filteredTablesprestataire = prestataires && prestataires.filter(
            item => {
                console.log('item', item)
                return (
                    (item.nom !== null )&& item
                        .nom
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.prenom  !== null ) && item
                        .prenom
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.adresse  !== null ) && item.adresse
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.ville  !== null ) && item
                        .ville
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.telephone  !== null ) && item.telephone
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.email  !== null ) && item.email
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase()) ||
                    (item.commentaire  !== null ) && item.commentaire
                        .toLowerCase()
                        .includes(searchFieldprestataire.toLowerCase())
                );
            }
        );
        if(searchFieldprestataire == '')
        getAllPrestatairevrai().catch(console.error);
        setPrestataires(filteredTablesprestataire);
    },[searchFieldprestataire]);


    function rechercheprestataireindb(){
        if(searchFieldprestataire !== ''){
            return;
        }
        return;
    }



    const getListeVehiculeprestataire = async () => {
        helpers
            .getAllVehiculeprestataire()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };




    useEffect( ()=>{
        async function init(){
            setLoading(true);
            await getListeVehiculeprestataire().catch(console.error);
            await getAllPrestatairevrai().catch(console.error);
            helpers
                .getAllSites()
                .then(async r => {
                    setLoading(false);
                    if (r.status === 200) {
                        const AllSites = [];
                        for(let i = 0; i<r.data.length; i++){
                            AllSites.push({value:r.data[i].id, label:r.data[i].nom})
                        }
                        await setSites(AllSites);
                    }
                })
        }
        init();
    },[]);


    const modificationvehiculeprestataire =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('commentaire');

        if(Immatriculation.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez insérer l\'immatriculation' });
            return;
        }
        if(!Prestataire?.email){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir un prestataire.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(Prestataire?.email && Prestataire?.email.trim() === "" || Prestataire?.email === '----' ){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir un prestataire.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        setOpenToast({...openSnack, setOpenToast:false});

        setLoadingSave(true);
        let body = {
            immatriculation: Immatriculation.trim(),
            prestatairevraiId: Prestataire.id,
            id:idvehiculeprestataire,
            commentaire: commentaire
        };
        // if(site)
        //     body.siteId = site;

        if(idvehiculeprestataire != null)
        helpers
            .vehiculeprestatairemodification(body)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getListeVehiculeprestataire().catch(console.error);
                    await getAllPrestatairevrai().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const getAllPrestatairevrai = async () => {
        helpers
            .getAllPrestatairevrai()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setPrestataires( r.data);
                }
            })
    };


    const uploadfiles = async (e) => {
        e.preventDefault();
        if(isAdmin !== "AAAA" ){
            const user_ = await JSON.parse(await ls.get('user'));
            setSite( user_?.siteId);
        }
        setShowModalUpload(true)
    };


    const handleFileChange = async (e) => {
        if (e.target.files.length) {
            const inputFiles = e.target.files;
            let arrayfiles = [];
            for await (const file_ of inputFiles){
                const fileExtension = file_?.type.split("/")[1];
                if (!allowedExtensions.includes(fileExtension)) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: "Veuillez n'ajouter que des fichiers csv s'il vous plaît."
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    return;
                }
                arrayfiles.push(file_);
            }
            setFiles(inputFiles);
        }
    };

    const handleParse = async () => {
        var arrayfiles0 = [];
        for await (let file_0 of files) {
            if (!file_0) {
                setOpenToast({
                    isOpenToast: true,
                    txt: "Veuillez n'ajouter que des fichiers valides."
                });
                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                return;
            }
        }

        setLoading(true);
        for await (let file_1 of files) {
            var reader = new FileReader();
            await reader.readAsText(file_1, "text/plain");
            reader.onload = async ({target}) => {
                const csv = await Papa.parse(target.result, { header: true });
                for await (let csvdata of csv.data) {
                    if(csvdata.Immatriculation && csvdata.Immatriculation !== "" ){
                        await arrayfiles0.push({...csvdata, siteId: site});
                        helpers
                            .sendVehiculeprestataire(csvdata)
                            .then(async r => {
                                setLoading(false);
                                if (r.status === 200) {
                                    await getListeVehiculeprestataire().catch(console.error);
                                    setShowModalUpload(false);
                                    setOpenToast({
                                        isOpenToast: true,
                                        txt: r.message
                                    });
                                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                                }
                            })
                    }
                }
            };
        }
    };

    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Véhicules prestataires</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Véhicules
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        {/*<Link to="/dashboard/AjoutVehiculeprestataire" style={{}} className="nav-link">*/}
                        {/*    <button*/}
                        {/*       // onClick={(e)=>newGroupes(e)}*/}
                        {/*        className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
                        {/*</Link>*/}
                        <a href="/dashboard/AjoutVehiculeprestataire" className="nav-link">
                            <button
                                // onClick={(e)=>newGroupes(e)}
                                className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>

                        </a>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-row justify-content-between">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        <div style={{marginTop:15, marginLeft:25}} className="row justify-content-start">
                            <button
                                onClick = {uploadfiles}
                                className={"btn btn-success px-4"}><i className="fas fa-cloud mr-2" />Télécharger les listes des véhicules des prestataires en format csv</button>
                        </div>
                        </div>



                        <DataTable
                            pagination
                            selectableRowsComponentProps={selectProps}
                            dense
                            columns={columns( ShowConfirmDelete)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                            setSite( row.siteId);
                            setImmatriculation(row?.immatriculation);
                            setCommentaire(row?.commentaire);
                            setPrestataire(row?.prestatairevrai || null);
                            setIdvehiculeprestataire(row?.id);
                            setshowmodalmodif(true);
                            }}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>

                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification véhicule</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationvehiculeprestataire} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    {/* { (isAdmin === "AAAA"  ) && <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="typex"
                                        select
                                        label="Site"
                                        value={ site}
                                        onChange={(event) => {
                                            setSite(event.target.value);
                                        }}
                                    >
                                        {sites.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>} */}
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="immatriculation"
                                        label="Immatriculation"
                                        name="immatriculation"
                                        autoFocus
                                        value={Immatriculation}
                                        onChange={(value)=>{setImmatriculation(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        //required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoFocus
                                        value={Commentaire}
                                        onChange={(value)=>{setCommentaire(value.target.value); }}
                                    />
                                    <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                                       <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                           Prestataire: {(Prestataire != null ? Prestataire?.nom : '----') + ' ' + (Prestataire != null ? Prestataire?.prenom : '' )}
                                       </div>
                                       <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                           email: {Prestataire != null ? Prestataire?.email  : '----'}
                                       </div>
                                       <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                           telephone: {Prestataire != null ? Prestataire?.telephone  : '----'}
                                       </div>
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                       <Paper
                                           component="form"
                                           sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                                       >
                                           <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                               placeholder="Rechercher"
                                               inputProps={{ 'aria-label': 'Rechercher' }}
                                               onChange={(e)=>{
                                                   e.preventDefault();
                                                   setSearchFieldprestataire(e.target.value);
                                               }}
                                               value={searchFieldprestataire}
                                           />
                                           <IconButton  onClick={() => {
                                               rechercheprestataireindb()
                                           }} type="button" sx={{ p: '5px' }} aria-label="search">
                                               <SearchIcon />
                                           </IconButton>
                                       </Paper>
                                       <DataTable
                                       pagination
                                       selectableRowsComponentProps={selectProps}
                                       dense
                                       columns={prestatairesTables}
                                       data={prestataires}
                                       selectableRows
                                       striped
                                       highlightOnHover
                                       persistTableHead
                                       fixedHeader={false}
                                       progressPending={false}
                                       noDataComponent={"Aucune données à afficher"}
                                       onRowClicked={(row, event) => {console.log('row clicked', row)}}
                                       onSelectedRowsChange={handleChange}
                                       paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                    />
                                    </div>
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationvehiculeprestataire}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={showModalUpload}
                    onClose={()=>setShowModalUpload(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                    >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajouter des fichiers CSV</h4>
                                <button onClick={()=>setShowModalUpload(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div style={{marginLeft:30}}>
                                <div style={{  marginTop: "3rem" }}>
                                    <input
                                        onChange={handleFileChange}
                                        id="csvInput"
                                        name="file"
                                        type="File"
                                        multiple
                                    />
                                </div>
                                <div style={{marginTop: "3rem"}}>
                                    <button
                                        onClick = {handleParse}
                                        className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Envoyer</button>
                                </div>
                                <div style={{ marginTop: "3rem" }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression véhicule
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>

        </LoadingOverlay>
    )
};
