import React, {useEffect, useState} from 'react'
import ls from "local-storage";
import {  useNavigate    } from "react-router-dom"
import TextField from "@mui/material/TextField/TextField";
import MenuItem from "@mui/material/MenuItem";
import {helpers} from "../services/api/helpers";
let isAdmin = ls.get('xxxx');
let AllGroupes_ = ls.get('ALLG');
const user_ =  ls.get('user');

let isAdmin_ls = ls.get('isAdmin_ls');
if(isAdmin === "AAAA"  && (isAdmin_ls === undefined || isAdmin_ls === null) ){
    ls.set('isAdmin_ls', 'SUPERADMIN');
    isAdmin_ls = ls.get('isAdmin_ls');
}

export default ()=> {
    const navigate = useNavigate();
    const [user, setUser] = useState(JSON.parse(user_));
    const [AllGroupes, setAllGroupes] = useState(JSON.parse(AllGroupes_));
    const [isAdmin_, setAdmin] = useState(isAdmin_ls);

    //

    const setlogOut =async (event)=> {
        event.preventDefault();
        await ls.remove('userToken');
        await ls.remove('xxxx');
        navigate("/", { replace: true });
    };

    return (
        <div>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav d-flex align-items-center">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item">
                        {user != null && user.site && <span style={{fontWeight:'bold'}}>
                            Site: <span style={{fontWeight:'normal'}}>{user.site}</span>
                        </span>}
                    </li>
                    {isAdmin === "AAAA" && <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="type"
                        select
                        label="Type"
                        value={isAdmin_}
                        onChange={async (event) => {
                            setAdmin(event.target.value);
                            await ls.set('user', JSON.stringify({...user, siteId: event.target.value.split('--//--')[0] }));
                            await ls.set('userToken', event.target.value.split('--//--')[1]);
                            ls.set('isAdmin_ls', event.target.value);
                            if(event.target.value === "SUPERADMIN"){
                                await ls.set('user', JSON.stringify({...user, siteId: null }));
                                await ls.set('userToken',AllGroupes[0].userToken);
                            }
                            window.location.reload()
                        }}
                    >
                        {AllGroupes.map((option) => (
                            <MenuItem key={option.value} value={option.value === 'SUPERADMIN' ? 'SUPERADMIN' : (option.value + '--//--'  + option.userToken)   }>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>}
                </ul>
                <ul  style={{ flexDirection:'row', alignItems:'center'}}  className="navbar-nav ml-auto">
                    <a href="/dashboard/admin"  style={{marginRight:20, color:'#575757',cursor:"pointer",}} className="link">
                        {user != null && user.email}
                    </a>
                    <i className="far fa-user" />
                    <div   onClick={setlogOut} style={{marginLeft:10, cursor:"pointer",}}>
                        Se deconnecter
                    </div>
                    <li className="nav-item">
                        {/*<a className="nav-link" data-widget="fullscreen" href="/dashboard" role="button">*/}
                        {/*    <i className="fas fa-expand-arrows-alt"></i>*/}
                        {/*</a>*/}
                    </li>
                </ul>
            </nav>
        </div>

    )

}
