import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import MenuItem from "@mui/material/MenuItem";
import ls from "local-storage";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
let isAdmin = ls.get('xxxx');


let columns = [];
if(isAdmin === "AAAA" ) {
    columns = (( ShowConfirmDelete, handleCheckItem) => [
        {
            name: 'Site',
            selector: row => row?.site?.nom,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Agent',
            selector: row => row.nom + ' ' + row.prenom + ' (' + row?.statut + ')',
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Téléphone',
            selector: row => row.telephone,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Adresse',
            selector: row => row.adresse,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Code [checkAdmin]',
            selector: row => row.code,
            sortable:true,
            wrap:true,
            style:{fontSize:16},
                cell: d => <span style={{flexDirection:'row', alignItems:'center'}}><span style={{ }}>{d.code}</span>{d.statut === "Titulaire" &&  <span style={{ marginLeft:4}}>
                    <FormControlLabel
                        checked={d.isAdmin === true}
                        control={<Checkbox onChange={handleCheckItem} value={d.id} color="primary" />}
                        label=""
                    />
                </span>}</span>,

        },
        {
            name: '',
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,
        }
    ]);

}else {
    columns = (( ShowConfirmDelete, handleCheckItem) => [
        {
            name: 'Agent',
            selector: row => row.nom + ' ' + row.prenom + ' (' + row?.statut + ')',
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Téléphone',
            selector: row => row.telephone,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Adresse',
            selector: row => row.adresse,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Code',
            selector: row => row.code,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Code [checkAdmin]',
            selector: row => row.code,
            sortable:true,
            wrap:true,
            style:{fontSize:16},
            cell: d => <span style={{flexDirection:'row', alignItems:'center'}}><span style={{ }}>{d.code}</span>{d.statut === "Titulaire" &&  <span style={{ marginLeft:4}}>
                    <FormControlLabel
                        checked={d.isAdmin === true}
                        control={<Checkbox onChange={handleCheckItem} value={d.id} color="primary" />}
                        label=""
                    />
                </span>}</span>,

        },
        {
            name: '',
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,
        }
    ]);
}




export const Agent_ = () => {
    const navigate = useNavigate();
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({ isOpenNomError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [idagent, setIdagent] = React.useState(null);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [isshowmodal, setshowmodal] = React.useState(false);
    const [Agent, setAgent] = React.useState(false);
    const [prenom, setPrenom] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [adresse, setAdresse] = React.useState("");
    const [code, setCode] = useState("");
    const [statut, setStatut] = useState("");
    const [sites, setSites] = React.useState([]);
    const [site, setSite] = React.useState('');
    const [statuts, ______ ] = useState([
        {
            value: 'Titulaire',
            label: 'Titulaire',
        },
        {
            value: 'Contractuel',
            label: 'Contractuel',
        },
        {
            value: 'Stagiaire',
            label: 'Stagiaire',
        }
    ]);


    const handleCheckItem = async (val) => {
        helpers
            .setAgentAdmin({id: val.target.value})
            .then(async r => {
                if (r.status === 200) {
                    getListeAgents().catch(console.error);
                }
            })
    };

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
        .supprAgent({id: idtosuppr})
        .then(async r => {
            setLoading(false);
            if (r.status === 200) {
                setOpenToast({
                    isOpenToast: true,
                    txt: r.message
                });
                getListeAgents().catch(console.error);
                setOpen(false);
            }
        })
    };

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.prenom !== null && item.prenom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.statut !== null && item?.statut
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.email !== null && item.email
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.telephone !== null && item.telephone
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getListeAgents().catch(console.error);
        if(filteredTables != undefined)
            setTables(filteredTables);
        // setTables({...tables, data: filteredTables});
    },[searchField]);


    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function rechercheprestataireindb(){


    }


    const getListeAgents = async () => {
        helpers
            .getAllAgents()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };


    useEffect( ()=>{
        async function init(){
            setLoading(true);
            setTimeout(()=> setLoading(false),10000 )
            await getListeAgents().catch(console.error);
                helpers
                    .getAllSites()
                    .then(async r => {
                        setLoading(false);
                        if (r.status === 200) {
                            const AllSites = [];
                            for(let i = 0; i<r.data.length; i++){
                                AllSites.push({value:r.data[i].id, label:r.data[i].nom})
                            }
                            await setSites(AllSites);
                        }
                    })
        }
        init();
    },[]);


    const modificationagent =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('commentaire');

        if(Agent.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez insérer le catégorie' });
            return;
        }

        setOpenToast({...openSnack, setOpenToast:false});

        setLoadingSave(true);
        let body = {
            nom: Agent.trim(),
            id:idagent,
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            statut: statut,
            code: code,
        };
        if(site)
            body.siteId = site;
        if(idagent != null)
        helpers
            .agentmodification(body)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getListeAgents().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };


    const ajoutagent =(e)=> {
        const data = new FormData(e.currentTarget);
        e.preventDefault();
        if(Agent.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez insérer le nom de l\' agent' });
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});

        if(site.toString().trim()  === ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez choisir le site.' });
            setTimeout(()=>setOpenToast({ isOpenToast:false, txt:' ' }),6000);
            return;
        }


        setLoadingSave(true);
        let dataX = {
            nom: Agent,
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            statut: statut,
            code: code,
        };
        if(site)
            dataX.siteId = site

        helpers
            .agentsave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    })
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);
                    await getListeAgents().catch(console.error);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Liste agent</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Agent
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                            <button
                               onClick={async (e)=>{
                                   e.preventDefault();
                                   setAgent("");
                                    setPrenom("");
                                    setEmail("");
                                    setTelephone("");
                                    setAdresse("");
                                   if(isAdmin !== "AAAA" ){
                                       const user_ = await JSON.parse(await ls.get('user'));
                                       setSite( user_?.siteId);
                                   }
                                   setshowmodal(true)
                               }}
                               // onPress={(e)=>{
                               //    }}
                                className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                        <DataTable
                            pagination
                            selectableRowsComponentProps={selectProps}
                            dense
                            columns={columns( ShowConfirmDelete, handleCheckItem)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                            setSite( row.siteId);
                            setAgent(row?.nom);
                            setIdagent(row?.id);
                            setStatut(row?.statut);
                            setCode(row?.code);
                            setPrenom(row?.prenom);
                            setEmail(row?.email);
                            setTelephone(row?.telephone);
                            setAdresse(row?.adresse);
                            setshowmodalmodif(true);
                            }}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>

                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification agent</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationagent} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    { (isAdmin === "AAAA"  ) && <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="typex"
                                        select
                                        label="Site"
                                        value={ site}
                                        onChange={(event) => {
                                            setSite(event.target.value);
                                        }}
                                    >
                                        {sites.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>}
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="statut"
                                            select
                                            label="Statut"
                                            value={statut}
                                            onChange={(event) => {
                                                setStatut(event.target.value);
                                            }}
                                        >
                                            {statuts.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    <TextField
                                       margin="normal"
                                        required
                                        fullWidth
                                        id="agent"
                                        label="Nom"
                                        name="agent"
                                        autoFocus
                                        value={Agent}
                                        onChange={(value)=>{setAgent(value.target.value); }}
                                    />
                                    <TextField
                                         margin="normal"
                                        required
                                        fullWidth
                                        id="prenom"
                                        label="Prénom"
                                        name="prenom"
                                        value={prenom}
                                        onChange={(value)=>{setPrenom(value.target.value); }}
                                    />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        value={email}
                                        onChange={(value)=>{setEmail(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="telephone"
                                        label="Téléphone"
                                        name="telephone"
                                        value={telephone}
                                        onChange={(value)=>{setTelephone(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="adresse"
                                        label="Adresse"
                                        name="adresse"
                                        value={adresse}
                                        onChange={(value)=>{setAdresse(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="code"
                                        label="Code à 4 chiffres"
                                        name="code"
                                        value={code}
                                        onChange={(value)=>{setCode(value.target.value); }}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationagent}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajout agent</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={ajoutagent} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    { isAdmin === "AAAA" && <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="type"
                                        select
                                        label="Site"
                                        value={site}
                                        onChange={(event) => {
                                            setSite(event.target.value);
                                        }}
                                    >
                                        {sites.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>}
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="statut"
                                            select
                                            label="Statut"
                                            value={statut}
                                            onChange={(event) => {
                                                setStatut(event.target.value);
                                            }}
                                        >
                                            {statuts.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    <TextField
                                       margin="normal"
                                        required
                                        fullWidth
                                        id="agent"
                                        label="Nom"
                                        name="agent"
                                        autoFocus
                                        value={Agent}
                                        onChange={(value)=>{setAgent(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="prenom"
                                        label="Prénom"
                                        name="prenom"
                                        value={prenom}
                                        onChange={(value)=>{setPrenom(value.target.value); }}
                                    />
                                    <TextField
                                       margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        value={email}
                                        onChange={(value)=>{setEmail(value.target.value); }}
                                    />

                                    <TextField
                                       margin="normal"
                                        required
                                        fullWidth
                                        id="telephone"
                                        label="Téléphone"
                                        name="telephone"
                                        value={telephone}
                                        onChange={(value)=>{setTelephone(value.target.value); }}
                                    />

                                    <TextField
                                       margin="normal"
                                        required
                                        fullWidth
                                        id="adresse"
                                        label="Adresse"
                                        name="adresse"
                                        value={adresse}
                                        onChange={(value)=>{setAdresse(value.target.value); }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="code"
                                        label="Code à 4 chiffres"
                                        name="code"
                                        value={code}
                                        onChange={(value)=>{if(code.length<4) {
                                            setCode(value.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {ajoutagent}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>


                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression agent
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>

        </LoadingOverlay>
    )
};
