import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import ls from "local-storage";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useNavigate    } from "react-router-dom"
import { baseUrl } from "../../services/constantes";
import { styled } from '@mui/system';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import useAutocomplete from '@mui/base/useAutocomplete';
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { EmailSharp } from "@mui/icons-material";
import { DialogContent, DialogContentText } from "@mui/material";
const url_icondechet = baseUrl + "/icondechet/";
let isAdmin = ls.get('xxxx');



let columns = [];
if(isAdmin === "AAAA" ) {
 columns = [
     {
         name: 'Site',
         selector: row => row?.site?.nom,
         sortable:true,
         wrap:true,
         style:{fontSize:16}
     },
    {
        name: 'Date',
        selector: row =>moment(row?.createdAt).format("HH:mm:ss DD/MM/yyyy"),
        sortable:true,
        wrap:true,

    },
    {
        name: 'Agent',
        selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom  ,
        // selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom + ' (' + row?.agent?.statut + ')',
        sortable:true,
        wrap:true,

    },
    {
        name: 'Statut',
        selector: row =>   row?.agent?.statut,
        sortable:true,
        wrap:true,

    },
    {
        name: 'Début',
        selector: row => row?.debut,
        cell: d => <span>{moment(d?.debut).format("HH:mm:ss DD/MM/YYYY")}</span>,
        wrap:true,
    },
    {
        name: 'Fin',
        selector: row =>row?.fin !== null ? moment(row?.fin).format("HH:mm:ss DD/MM/YYYY") : '----',
        sortable:true,
        wrap:true,

    },
    {
        name: 'Temps de travail',
      //  selector: row => row?.agent?.nom,
        cell: d => <span>{d?.fin !== null ? (moment.duration(moment(d?.fin).diff(moment(d?.debut))).hours() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).minutes() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).seconds()) : '----'}</span>,
        sortable:true,
        wrap:true,
    },
    {
        name: 'Etat',
        selector: row =>row?.etat !== null ? row?.etat : '----',
        sortable:true,
        wrap:true,

    },
];
}else {
    columns = [
        {
            name: 'Date',
            selector: row =>moment(row?.createdAt).format("HH:mm:ss DD/MM/yyyy"),
            sortable:true,
            wrap:true,

        },
        {
            name: 'Agent',
            selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom  ,
            // selector: row => row?.agent?.nom + ' ' + row?.agent?.prenom + ' (' + row?.agent?.statut + ')',
            sortable:true,
            wrap:true,

        },
        {
            name: 'Statut',
            selector: row =>   row?.agent?.statut,
            sortable:true,
            wrap:true,

        },
        {
            name: 'Début',
            selector: row => row?.debut,
            cell: d => <span>{moment(d?.debut).format("HH:mm:ss DD/MM/YYYY")}</span>,
            wrap:true,
        },
        {
            name: 'Fin',
            selector: row =>row?.fin !== null ? moment(row?.fin).format("HH:mm:ss DD/MM/YYYY") : '----',
            sortable:true,
            wrap:true,

        },
        {
            name: 'Temps de travail',
            //  selector: row => row?.agent?.nom,
            cell: d => <span>{d?.fin !== null ? (moment.duration(moment(d?.fin).diff(moment(d?.debut))).hours() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).minutes() + ":" + moment.duration(moment(d?.fin).diff(moment(d?.debut))).seconds()) : '----'}</span>,
            sortable:true,
            wrap:true,
        },
        {
            name: 'Etat',
            selector: row =>row?.etat !== null ? row?.etat : '----',
            sortable:true,
            wrap:true,

        },
    ];
}

export const Centre = () => {
    const navigate = useNavigate();
    const [openModalSuppression, setopenModalSuppression] = useState(false);
    const [selected, setSelected] = useState({ allSelected:false, selectedCount:0, selectedRows:[] });
    const [openModalSuppressionemailexcel, setopenModalSuppressionemailexcel] = useState(false);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState({columns:columns});
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [Emails, setEmails] = useState( []);
    const [emailexcel, setemailexcel] = useState( "");
    const [emailexcelid, setemailexcelid] = useState( "");
    const [emailexcelsuppr, setemailexcelsuppr] = useState( "");

    const ajoutemailexcel = async (e) => {
        e.preventDefault();

        if(emailexcel === ""){
        setOpenToast({
            isOpenToast: true,
            txt: "Veuillez ajouter l'email.",
        });
        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
        return;
        }

        setLoading(true);
        setTimeout(()=>{ setLoading(false); },6000);
        helpers
            .ajoutemailexcel({email: emailexcel})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    getemailexcel()
                    setemailexcel("")
                }
            })
    }


    const suppressionemailexcel = async (e) => {
        e.preventDefault();
        
        helpers
            .suppremailexcel({id:emailexcelid})
            .then(async r => {
                if (r.status === 200) {
                    setopenModalSuppressionemailexcel(false);
                    getemailexcel().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppressionemailexcel(false);
                }
            }).catch((r)=>{  
                setopenModalSuppressionemailexcel(false);
            })
    };

    const getemailexcel = async () => {
        helpers
        .getemailexcel()
        .then(async r => {
            if (r.status === 200) {
                setEmails(r.data);
            }
        })
    }


    const suppression = async (e) => {
        e.preventDefault();
        if(selected.selectedCount==0)
            return;
        setLoading(true);
        helpers
            .supprpointage(selected.selectedRows)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    getPointage().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false);
                }else{
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false)
                }
            }).catch((r)=>{
                setOpenToast({
            isOpenToast: true,
            txt: r.message,
        });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

        })
    };



    useEffect(()=>{
        const filteredPersons = tables.data && tables.data.filter(
            item => {
                return (
                    item?.agent?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    ||
                    item?.agent?.prenom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                    ||
                    item?.site?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == ''){
            getPointage().catch(console.error);
        }
        setTables({...tables, data: filteredPersons});
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }



    function rechercherpardate(e) {

        setLoading(true);
        e.preventDefault();
        helpers
            .pointagepardate({startDate:startDate, endDate:endDate})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables({...tables, data: r.data});
                }
            })

    }


    const getPointage = async () => {
        helpers
            .getpointage()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    console.log('rdta',r.data);
                    setTables({...tables, data: r.data});
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getPointage().catch(console.error);
        getemailexcel().catch(console.error)

    },[]);

   




    function envoyerexcel(e) {
        e.preventDefault();
        helpers
            .pointageexcel({excel:tables.data})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    const link = document.createElement('a');
                    link.setAttribute('href', encodeURI(r.url));
                    // link.setAttribute('download', 'rapport.csv');
                    await link.click();
                }
            })
    
    }

  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Pointage</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    Pointage
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                    City collect
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                {/*<button*/}
                {/*    onClick={(e)=>newDepot(e)}*/}
                {/*    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              {/*<div className="card-header">*/}
              {/*    <h3 className="card-title">DataTable with default features</h3>*/}
              {/*</div>*/}

              <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                      <button
                          onClick={(e)=>setopenModalSuppression(true)}
                          className={"btn btn-danger px-4"}><i className="fas fa-trash mr-2" />Supprimer</button>
                      <div  style={{ marginLeft:10, top:20, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div style={{marginRight:5}}>Début</div>
                          <div style={{}}>
                              <DatePicker locale="fr" showTimeSelect dateFormat="Pp" selected={startDate} onChange={(date) => setStartDate(date)} />
                          </div>
                      </div>
                      <div  style={{  marginLeft:10, marginRight:10, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div style={{}}>Fin</div>
                          <div style={{marginLeft:5}}>
                              <DatePicker locale="fr" showTimeSelect dateFormat="Pp"   selected={endDate} onChange={(date) => setEndDate(date)} />
                          </div>
                      </div>
                      <button
                          onClick={(e)=>rechercherpardate(e)}
                          className={"btn btn-info px-2"}><i className="fas fa-search mr-2" />Rechercher</button>
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  </div>

                  <DataTableExtensions
                      {...tables}
                      filter={false}
                      //filterHidden={false}
                     print={false}
                     export={false}
                     filterPlaceholder={'Rechercher'}
                  >
                  <DataTable
                      pagination
                      dense
                      selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      // onRowClicked={(row, event) => {
                      //     setItemModif(row);
                      //         setshowmodalModif(true)
                      // }}
                      onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                          const se = { allSelected:allSelected, selectedCount:selectedCount, selectedRows:selectedRows };
                            setSelected(se)
                      }}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
                  </DataTableExtensions>
              </div>
              {/*fin body*/}
          </div>

          


          <Dialog
              open={openModalSuppression}
              keepMounted
              onClose={()=>setopenModalSuppression(false)}
              aria-describedby="alert-dialog-slide-description"   >
              <DialogTitle>{"Voulez-vous vraiment supprimer?"}</DialogTitle>
              <DialogActions>
                  <Button onClick={()=>setopenModalSuppression(false)}>Annuler</Button>
                  <Button onClick={suppression}>Ok</Button>
              </DialogActions>
          </Dialog>
          <Dialog
              open={openModalSuppressionemailexcel}
              keepMounted
              onClose={()=>setopenModalSuppressionemailexcel(false)}
              aria-describedby="alert-dialog-slide-description"   >
              <DialogTitle>{"Voulez-vous vraiment supprimer?"}</DialogTitle>
              <DialogContentText style={{marginLeft:25}}>{emailexcelsuppr}</DialogContentText>
              <DialogActions>
                  <Button onClick={()=>setopenModalSuppression(false)}>Annuler</Button>
                  <Button onClick={suppressionemailexcel}>Ok</Button>
              </DialogActions>
          </Dialog>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
           <div style={{paddingBottom:40}} className="col-sm-6">
                <h3 style={{marginTop:10,  }} className="mb-80 text-dark">Email pour envoi pointage</h3>
               {
               Emails.length>0 && Emails.map(({email, id})=>{
                return <div style={{display:'flex', flexDirection:'row', alignItems:'center',  marginTop:15}} >
                    <div style={{fontSize:18, fontWeight:'bold', color:'grey',}}>{email}</div>
                    <div style={{marginLeft:10}}>
                    <button
                    style={{borderWidth:0}}
                    onClick={(e)=>{e.preventDefault(); setemailexcelsuppr(email), setemailexcelid(id); setopenModalSuppressionemailexcel(true)}}
                    ><i className="fas fa-trash mr-2" /></button>
                </div>
                </div>
                }
               )} 
                <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', marginTop:'15px', justifyContent:'space-between', alignItems: 'center', width: 400 }}
                  >
                <div >
                    <InputBase
                    style={{borderWidth:1}}
                        placeholder="Ajouter email."
                        inputProps={{ 'aria-label': 'Rechercher' }}
                        onChange={(e)=>{
                            e.preventDefault();
                                setemailexcel(e.target.value);
                        }}
                        value={emailexcel}
                    />
                </div>

                <button
                    onClick={(e)=>ajoutemailexcel(e)}
                    className={"btn btn-info px-2"}><i className="fas fa-save mr-2" />Enregistrer</button>
                </Paper>
                <button
                    style={{marginTop:20}}
                    onClick={(e)=>envoyerexcel(e)}
                    className={"btn btn-success  "}>Exportation fichier pointage</button>
            </div>
      </div>
      </LoadingOverlay>
  )
};
