import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {helpers} from "../../services/api/helpers";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import { baseUrl } from "../../services/constantes";
import { styled } from '@mui/system';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import useAutocomplete from '@mui/base/useAutocomplete';

const url_icondechet = baseUrl + "/icondechet/"


const Label = styled('label')({
    display: 'block',
});

const Input = styled('input')(({ theme }) => ({
    width: 220,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
}));

const Listbox = styled('ul')(({ theme }) => ({
    width: 220,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
    },
    '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
    },
}));

const columns = (( ShowConfirmDelete) => [
    {
        name: 'Icone',
        wrap:false,
        cell: (row) => <div style={{ margin:4 }}>{row?.icon && row?.icon != null ? <img  src={ url_icondechet + row?.icon} style={{width:100, height: 100}} /> : <img  src={require("../../assets/thelogo.png").default} style={{width:100, height: 100}} />}</div>,
    },
    {
        name: 'Type',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Catégorie',
        selector: row => row?.categorietypedechet?.categorie,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);



export const TypeDechet_ = () => {
    const navigate = useNavigate();
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({ isOpenNomError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [Type, setType] = React.useState(false);
    const [idtypeDechet, setIdtypeDechet] = React.useState(null);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [isshowmodal, setshowmodal] = React.useState(false);

    const [categories, setCategories] = React.useState([]);
    const [CategorieOnChange, setCategorieOnChange] = useState(null);
    const [urlImage, setUrlimage] = useState(null);
    const getCategorieToDropdown = async () => {
        helpers
            .getAllCategoriesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setCategories( r.data);
                }
            })
    };

    useEffect(()=>{
        getCategorieToDropdown().catch(console.error);

    },[]);


    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: categories,
        getOptionLabel: (option) =>{return  option.label},
        onChange: async (option, val) =>{
            console.log('op', val);
            await setCategorieOnChange(val);
        },
    });

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprTypeDechet({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    getListeTypeDechets().catch(console.error);
                    setOpen(false);
                }
            })
    };

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item
                        .nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .categorietypedechet?.categorie
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getListeTypeDechets().catch(console.error);
        setTables(filteredTables);
    },[searchField]);


    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function rechercheprestataireindb(){


    }



    const getListeTypeDechets = async () => {
        helpers
            .getAllTypeDechets()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    useEffect( ()=>{
        async function init(){
            setLoading(true);
            await getListeTypeDechets().catch(console.error);
        }
        init();
    },[]);

    const [file, setFile] = useState(undefined);
    const handleFileChange = async (e) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            setFile(inputFile);
        }
    };

    const modificationtypeDechet =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);


        // if (CategorieOnChange === null) {
        //     setOpenToast({
        //         isOpenToast: true,
        //         txt: "Veuillez choisir la catégorie."
        //     });
        //     return;
        // }


        if(Type.trim() === ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez insérer le type de dechet'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

            return;
        }

        setOpenToast({...openSnack, setOpenToast:false});

        setLoadingSave(true);
        let body = {
            nom: Type.trim(),
            id:idtypeDechet
            //commentaire: commentaire
        };
        if(CategorieOnChange != null && CategorieOnChange.label)
            body.categorietypedechetId = CategorieOnChange.id;


        if (!file) {
            if(idtypeDechet != null)
                helpers
                    .typeDechetmodification(body)
                    .then(async r => {
                        setLoadingSave(false);
                        if (r.status === 200) {
                            await getListeTypeDechets().catch(console.error);
                            setOpenToast({
                                isOpenToast: true,
                                txt: r.message
                            });
                            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                            setshowmodalmodif(false);

                        } else {
                            setOpenToast({
                                isOpenToast: true,
                                txt: r.message,
                            });
                            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

                        }
                    })
                    .catch(e => {
                        setLoadingSave(false);
                    });
        }else{
            const createXHR = () => new XMLHttpRequest()
            const formData = new FormData()
            formData.append('data', file)
            fetch(baseUrl + '/upload/icondechet', {
                body: formData,
                createXHR,
                method: 'POST',
                headers: {
                    // 'content-type': file.type,
                    // 'Content-Type': 'multipart/form-data',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    'content-length': `${file.size}`, // 👈 Headers need to be a string
                },
            }).then((res) =>{
                if(res.status === 200){
                    // body.icon = res.originalName;
                    body.icon = file.name;

                    if(idtypeDechet != null)
                        helpers
                            .typeDechetmodification(body)
                            .then(async r => {
                                setLoadingSave(false);
                                if (r.status === 200) {
                                    await getListeTypeDechets().catch(console.error);
                                    setOpenToast({
                                        isOpenToast: true,
                                        txt: r.message
                                    });
                                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                                    setshowmodalmodif(false);

                                } else {
                                    setOpenToast({
                                        isOpenToast: true,
                                        txt: r.message,
                                    });
                                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                                }
                            })
                            .catch(e => {
                                setLoadingSave(false);
                            });

                }

            })
        }

    };


    const ajouttypeDechet =(e)=> {
        const data = new FormData(e.currentTarget);
        // const formData = new FormData();
        // formData.append('profileImg', this.state.profileImg)
        e.preventDefault();



        let dataX = {
            nom: Type,
        };




        if (CategorieOnChange === null) {
            setOpenToast({
                isOpenToast: true,
                txt: "Veuillez choisir la catégorie."
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }

        if(CategorieOnChange != null && CategorieOnChange.label)
            dataX.categorietypedechetId = CategorieOnChange.id;
        setLoadingSave(true);


        if (!file) {
            if(idtypeDechet != null)
                helpers
                    .typesave(dataX)
                    .then(async r => {
                        setLoadingSave(false);
                        if (r.status === 200) {
                            await getListeTypeDechets().catch(console.error);
                            setOpenToast({
                                isOpenToast: true,
                                txt: r.message
                            });
                            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                            setshowmodalmodif(false);

                        } else {
                            setOpenToast({
                                isOpenToast: true,
                                txt: r.message,
                            });
                            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                        }
                    })
                    .catch(e => {
                        setLoadingSave(false);
                    });
        }else{
            const createXHR = () => new XMLHttpRequest()
            const formData = new FormData()
            formData.append('data', file)
            fetch(baseUrl + '/upload/icondechet', {
                body: formData,
                createXHR,
                method: 'POST',
                headers: {
                    'content-length': `${file.size}`, // 👈 Headers need to be a string
                },
            }).then((res) =>{
                if(res.status === 200) {
                    // body.icon = res.originalName;
                    dataX.icon = file.name;
                    helpers
                        .typesave(dataX)
                        .then(async r => {
                            setLoadingSave(false);
                            if (r.status === 200) {
                                setOpenToast({
                                    isOpenToast: true,
                                    txt: r.message
                                });
                                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                                setshowmodal(false);
                                await getListeTypeDechets().catch(console.error);
                            } else {
                                setOpenToast({
                                    isOpenToast: true,
                                    txt: r.message,
                                });
                                setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

                            }
                        })
                        .catch(e => {
                            setLoadingSave(false);
                        });
                }
            });

        }
    };

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };





    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Type de dechets</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Dechet
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                            City collect
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        <button
                            onClick={(e)=>{
                                e.preventDefault();
                                setType("");
                                setshowmodal(true)
                            }}
                            // onPress={(e)=>{
                            //    }}
                            className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>

                        <DataTable
                            pagination
                            selectableRowsComponentProps={selectProps}
                            dense
                            columns={columns( ShowConfirmDelete)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                                setType(row?.nom);
                                setUrlimage( row?.icon);
                                setIdtypeDechet(row?.id);
                                setshowmodalmodif(true);
                            }}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>

                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationtypeDechet} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    <div style={{ margin:4 }}>{urlImage != null ? <img  src={ url_icondechet + urlImage} style={{width:100, height: 100}} /> : <img  src={require("../../assets/thelogo.png").default} style={{width:100, height: 100}} />}</div>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="type"
                                        label="Type"
                                        name="type"
                                        autoFocus
                                        value={Type}
                                        onChange={(value)=>{setType(value.target.value); }}
                                    />
                                    <div style={{  marginBottom: "1rem", marginTop: "2rem" }}>
                                        <input
                                            onChange={handleFileChange}
                                            id="csvInput"
                                            name="file"
                                            type="File"
                                            accept=".png,.jpg,.ico"
                                        />
                                    </div>

                                    <div  style={{  marginBottom: "3rem" }}>
                                        <div {...getRootProps()}>
                                            <Label {...getInputLabelProps()}>Catégorie</Label>
                                            <Input {...getInputProps()} />
                                        </div>
                                        {groupedOptions.length > 0 ? (
                                            <Listbox {...getListboxProps()}>
                                                {groupedOptions.map((option, index) => (
                                                    <li {...getOptionProps({ option, index })}>{option.label}</li>
                                                ))}
                                            </Listbox>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationtypeDechet}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow: 'scroll' }}

                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ajout</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={ajouttypeDechet} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="type"
                                        label="Type"
                                        name="type"
                                        autoFocus
                                        value={Type}
                                        onChange={(value)=>{setType(value.target.value); }}
                                    />
                                    <div style={{  marginBottom: "1rem", marginTop: "2rem" }}>
                                        <input
                                            onChange={handleFileChange}
                                            id="csvInput"
                                            name="file"
                                            type="File"
                                            accept=".png,.jpg,.ico"
                                        />
                                    </div>

                                    <div  style={{  marginBottom: "3rem" }}>
                                        <div {...getRootProps()}>
                                            <Label {...getInputLabelProps()}>Catégorie</Label>
                                            <Input {...getInputProps()} />
                                        </div>
                                        {groupedOptions.length > 0 ? (
                                            <Listbox {...getListboxProps()}>
                                                {groupedOptions.map((option, index) => (
                                                    <li {...getOptionProps({ option, index })}>{option.label}</li>
                                                ))}
                                            </Listbox>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {ajouttypeDechet}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>


                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression type de dechet
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>

        </LoadingOverlay>
    )
};
