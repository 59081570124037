import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import DataTable from "react-data-table-component";
import AjouterPrestataire from "./AjouterPrestatairesimple";
import {helpers} from "../../services/api/helpers";
import LoadingOverlay from "react-loading-overlay";
import TextField from "@mui/material/TextField/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Delete from '@mui/icons-material/Delete';

const columns = (( ShowConfirmDelete ) => [
    {
        name: 'Nom & Prenoms',
        selector: row => row?.nom + ' ' +row?.prenom,
        cell: d => <span>{(d.nom != null ? d?.nom : '') + ' ' + (d?.prenom != null ? d?.prenom : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:14}
    },
    {
        name: 'Ville',
        selector: row => row?.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row?.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row?.telephone,
        wrap:true,
    },
    // {
    //     name: 'nb Véhicules',
    //     selector: row => row?.vehicules.length,
    //     wrap:true,
    // },
    // {
    //     name: 'Immatriculation',
    //     selector: row => (row?.vehicules[0]?.immatriculation ? row?.vehicules[0]?.immatriculation : '') +  (row?.vehicules[1]?.immatriculation ? '/' + row?.vehicules[1]?.immatriculation: ''),
    //     wrap:true,
    // },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);




export const Centre = () => {
    const divRef = React.useRef(null);

    const [switchPrestataire, setSwitchPrestataire] = React.useState('Afficher');
    const [searchField, setSearchField] = useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [isLoadingModif, setLoadingmodif] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [nommodif, setNommodif] = useState("");
    const [prenommodif, setPrenommodif] = useState("");
    const [adressemodif, setAdressemodif] = useState("");
    const [villemodif, setVillemodif] = useState("");
    const [telephonemodif, setTelephonemodif] = useState("");
    const [emailmodif, setEmailmodif] = useState("");
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [idprestataire, setIdprestataire] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [idtosuppr, setIdToSuppr] = useState(null);

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };

    const handleSwitchPrestataire = (event) => {
        setSwitchPrestataire(event.target.value);
    };
    const setByChildLoading = (loading) => {
        setLoading(loading);
    };
    const setByChildOpenToast = (toast) => {
        setOpenToast(toast);
    };
    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const getPrestataire = async () => {
        helpers
            .getAllPrestataires()
            .then(async r => {
                console.log('resp', r);
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };



    useEffect(()=>{
        setLoading(true);
        getPrestataire().catch(console.error);
    },[]);


    const modificationprestataire =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('commentaire');

        if(nommodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le nom du prestataire.' });
            return;
        }
        if(prenommodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le prénom du prestataire.' });
            return;
        }
        if(adressemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer l\'adresse du prestataire.' });
            return;
        }
        if(villemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer la ville du prestataire.' });
            return;
        }
        if(telephonemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le téléphone du prestataire.' });
            return;
        }
        if(emailmodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer l\'email du prestataire.' });
            return;
        }

        setOpenToast({ isOpenToast: false, txt: '' });
        setLoadingmodif(true);

        let body = {
            nom: nommodif,
            prenom: prenommodif,
            adresse: adressemodif,
            ville: villemodif,
            email: emailmodif,
            telephone: telephonemodif,
            id:idprestataire,
            commentaire: commentaire,
        };

        if(idprestataire != null)
            helpers
                .prestatairemodification(body)
                .then(async r => {
                    setLoadingmodif(false);
                    if (r.status === 200) {
                        await getAllPrestataire().catch(console.error);
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message
                        });
                        setshowmodalmodif(false);
                    } else {
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message,
                        });
                    }
                })
                .catch(e => {
                    setLoadingmodif(false);
                });
    };



    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprPrestataire({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    getPrestataire().catch(console.error);
                    setOpen(false);
                }
            })
    };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Identité Prestataire</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                Prestataires
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                City collect
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>


          <div className="col" style={{ marginBottom:20}}>

              <div style={{ justifyContent:'flex-start', marginLeft:50 }} className=" d-flex flex-row ">
                  <div className="row d-flex flex-row align-items-center justify-content-between">
                      <FormControl>
                          <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={switchPrestataire}
                              onChange={handleSwitchPrestataire}
                          >
                              <FormControlLabel value="Ajouter" control={<Radio />} label="Ajouter" />
                              <FormControlLabel value="Afficher" control={<Radio />} label="Afficher" />
                          </RadioGroup>
                      </FormControl>
                      {switchPrestataire === 'Afficher' && <Paper
                          component="form"
                          sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                      >
                          <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Rechercher"
                              inputProps={{ 'aria-label': 'Rechercher' }}
                              onChange={(e)=>{
                                  e.preventDefault();
                                  setSearchField(e.target.value);
                              }}
                              value={searchField}
                          />
                          <IconButton  onClick={() => {
                              rechercheindb()
                          }} type="button" sx={{ p: '5px' }} aria-label="search">
                              <SearchIcon />
                          </IconButton>
                      </Paper>}
                  </div>
              </div>
              {switchPrestataire === 'Afficher' && <DataTable
                  pagination
                  dense
                  columns={columns( ShowConfirmDelete)}
                  data={tables}
                  striped
                  highlightOnHover
                  persistTableHead
                  fixedHeader={false}
                  progressPending={false}
                  noDataComponent={"Aucune données à afficher"}
                  onRowClicked={(row, event) => {console.log('row clicked', row);
                      setNommodif(row?.nom || "");
                      setPrenommodif(row?.prenom || "");
                      setAdressemodif(row?.adresse || "");
                      setVillemodif(row?.ville || "");
                      setTelephonemodif(row?.telephone || "");
                      setEmailmodif(row?.email || "");
                      setIdprestataire(row?.id || null);
                      setshowmodalmodif(true);
                  }}
                  paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
              />}
              {switchPrestataire === 'Ajouter' && <div className={"w-100 bg-light"} ><div className={"w-50"}><AjouterPrestataire setSwitchPrestataire={handleSwitchPrestataire} getPrestataire={getPrestataire} setOpenToast={setByChildOpenToast} setParentLoading={setByChildLoading}/></div></div>}
          </div>

      </div>
            <Modal
                open={isshowmodalmodif}
                onClose={()=>setshowmodalmodif(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: 'scroll' }}

            >
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Modification prestataire</h4>
                            <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Box component="form" onSubmit={modificationprestataire} sx={{ mt: 1 }}
                             Validate
                             autoComplete="on"   >

                            <div ref={divRef}  className="modal-body">
                                {/*<TextField*/}
                                {/*    margin="normal"*/}
                                {/*    required*/}
                                {/*    fullWidth*/}
                                {/*    id="societe"*/}
                                {/*    label="Société"*/}
                                {/*    name="societe"*/}
                                {/*    autoFocus*/}
                                {/*    //  autoComplete="nom"*/}
                                {/*    value={societemodif}*/}
                                {/*    onChange={(value)=>{setSocietemodif(value.target.value); }}*/}
                                {/*/>*/}
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="nom"
                                    label="Nom"
                                    name="nom"
                                    autoFocus
                                    value={nommodif}
                                    onChange={(value)=>{setNommodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="prenom"
                                    label="Prénom"
                                    name="prenom"
                                    autoFocus
                                    value={prenommodif}
                                    onChange={(value)=>{setPrenommodif(value.target.value); }}
                                />

                                <TextField
                                    margin="normal"
                                  //  required
                                    fullWidth
                                    id="adresse"
                                    label="Adresse"
                                    name="adresse"
                                    autoFocus
                                    value={adressemodif}
                                    onChange={(value)=>{setAdressemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                  // required
                                    fullWidth
                                    id="ville"
                                    label="Ville"
                                    name="ville"
                                    autoFocus
                                    value={villemodif}
                                    onChange={(value)=>{setVillemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    //required
                                    fullWidth
                                    id="telephone"
                                    label="Téléphone"
                                    name="telephone"
                                    autoFocus
                                    value={telephonemodif}
                                    onChange={(value)=>{setTelephonemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                   // required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    name="email"
                                    autoFocus
                                    value={emailmodif}
                                    onChange={(value)=>{setEmailmodif(value.target.value); }}
                                />
                            </div>
                            <div className="modal-footer d-flex flex-row justify-content-between">
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                                <Button
                                    onPress = {modificationprestataire}
                                    type="submit"
                                    variant="contained"
                                    startIcon={
                                        isLoadingModif ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                            : <SaveIcon fontSize="inherit" />
                                    }
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </Box>

                    </div>
                </div>


            </Modal>
            <Snackbar
                open={openToast.isOpenToast}
                autoHideDuration={6000}
                message={openToast.txt}
                action={<React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="fermer"
                        color="inherit"
                        onClick={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpenToast(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>}
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression prestataire
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous ment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
  )
};
