import React, { Component } from 'react'
import {Link} from "react-router-dom";
import ls from "local-storage";
let isAdmin= ls.get('xxxx');

export default class Menu extends Component {

  render() {
    isAdmin= ls.get('xxxx');
    const user_ =  JSON.parse( ls.get('user'));

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="/dashboard" className="brand-link">
            {/*<img src={require("../assets/thelogo.png").default} alt="Memdom Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8', width:70, height:70}} />*/}
            <img src={require("../assets/thelogo.png").default} alt="City collect Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <span className="brand-text font-weight-light">City collect</span>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                <div  style={{ color:'#c2c7d0',cursor:"pointer"}} className="link">
                  {user_ != null && user_.nom}
                </div>
              </div>
            </div>
      {/* Sidebar Menu */}
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}


          {/*<li className="nav-item">*/}
          {/*  <Link to="/dashboard/sites" style={{}} className="nav-link">*/}
          {/*    <i className="far fa-circle nav-icon" />*/}
          {/*    <p>Sites</p>*/}
          {/*  </Link>*/}
          {/*</li>*/}

          <li className="nav-item">
            {/*<Link to="/dashboard/agent" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Agents</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/agent" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Agents</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/pointage" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Pointage</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/pointage" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Pointage</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/incident" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Incident rencontré</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/incident" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Incident rencontré</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/etatdelieu" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Etat de lieux</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/etatdelieu" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Fiche d'ouverture</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/epi" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>EPI</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/epi" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Equipement</p>
            </a>
          </li>
          {/*/////////////////////////////////////////////////////////////////////////////////////////<li className="nav-header" style={{marginTop:-20}}>Dechets</li>*/}

          <li className="nav-item">
            {/*<Link to="/dashboard/vidage" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Dechet sortant</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/vidage" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Dechet sortant</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/depot" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Dépôt Déchet</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/depot" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Dépôt Déchet</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/recuperation" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Récuperation</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/recuperation" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Récuperation</p>
            </a>
          </li>
          <li className="nav-item has-treeview menu-open">
            <a   className="nav-link ">
              {/*active*/}
              <i className="nav-icon fas fa-plus-circle"></i>
              <p>
                CONFIGURATION
                <i className="right fas fa-angle-left"></i>
              </p>
            </a>
          </li>
            {/* <li className="nav-item">
            <a href="/dashboard/vehicules" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Listes des véhicules</p>
            </a>
          </li> */}
          <li className="nav-item">
            {/*<Link to="/dashboard/listeincident" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Listes des incidents</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/listeincident" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Listes des incidents</p>
            </a>
          </li>

          <li className="nav-item">
            {/*<Link to="/dashboard/listetatdelieu" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Item Etat de lieux</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/listetatdelieu" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Liste fiche d'ouverture</p>
            </a>
          </li>

          <li className="nav-item">
            {/*<Link to="/dashboard/listepi" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Listes des EPI</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/listepi" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Liste équipement</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/categoriedechet" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Catégories dechets</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/categoriedechet" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Catégories dechets</p>
            </a>
          </li>
          <li className="nav-item">
            {/*<Link to="/dashboard/typedechet" style={{}} className="nav-link">*/}
            {/*  <i className="far fa-circle nav-icon" />*/}
            {/*  <p>Types de dechets</p>*/}
            {/*</Link>*/}
            <a href="/dashboard/typedechet" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Types de dechets</p>
            </a>
          </li>

          <li className="nav-item">
            <a href="/dashboard/unitedechet" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Unité dechet</p>
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="/dashboard/prestataire" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Prestataire</p>
            </a>
          </li> */}
          <li className="nav-item">
            <a href="/dashboard/vehiculeprestataire" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Véhicules Usagers</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/prestataire-dechets-sortant" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Prestataire</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/vehiculeprestataire-dechets-sortant" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Véhicules prestataires</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/sitetraitement" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Site de traitement</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/typevehicule" className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Type de véhicule</p>
            </a>
          </li>
          {isAdmin === "AAAA" && <li className="nav-item">
                <a href="/dashboard/site" className="nav-link">
                    <i className="far fa-circle nav-icon" />
                    <p>Site</p>
                </a>
            </li>}


        </ul>


      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>

        )
    }
}
